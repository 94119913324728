* {
	box-sizing: border-box;
}

html,
body {
	margin: 0;
	padding: 0;
}

body {
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--color);
	background: linear-gradient(61deg, var(--background-color) 0%, var(--background-color-gradient) 100%);
	font-size: 16px;
}

a {
	color: var(--color2);

	&:hover,
	&:focus {
		color: var(--primary);
		text-decoration: underline;
	}
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}