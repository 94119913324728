$white: #ffffff;
$black: #0c0c0c;
$light: #e8e8e8;
$dark: #1c1c1c;
$grey: #494949;
$grey-light: #9b9b9b;
$primary: #38dab0;
$primary-dark: #0c4435;
$primary-very-dark: darken($primary, 80%);
$primary-light: lighten($primary, 20%);
$secondary: #d43357;
$tertiary: #eb7448;

$warn: hsl(1, 70%, 50%);
$success: hsl(125, 70%, 40%);
$info: hsl(61, 70%, 40%);

$spacer: 2rem;

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px,
);

$smMax: map-get($grid-breakpoints, sm);
$mdMin: map-get($grid-breakpoints, sm) + 1px;
$mdMax: map-get($grid-breakpoints, md);
$lgMin: map-get($grid-breakpoints, md) + 1px;
$lgMax: map-get($grid-breakpoints, lg);
$xlMin: map-get($grid-breakpoints, lg) + 1px;
$xlMax: map-get($grid-breakpoints, xl);
$xxlMin: map-get($grid-breakpoints, xl) + 1px;
$xxlMax: map-get($grid-breakpoints, xxl);

// set css vars depending on color scheme
:root {
	--primary: #{$primary};
	--white: #{$white};
	--black: #{$black};
	--background-color: #{$light};
	--background-color-gradient: #{$white};
	--foreground-color: #{$dark};
	--color: #{$grey};
	--color2: #{$grey-light};
	--shadow-color: #{$black};
}

@media (prefers-color-scheme: dark) {
	:root {
		--primary: #{$primary};
		--white: #{$white};
		--black: #{$black};
		--background-color: #{$dark};
		--background-color-gradient: #{$black};
		--foreground-color: #{$light};
		--color: #{$light};
		--color2: #{$grey-light};
		--shadow-color: #38dab055;
	}
}