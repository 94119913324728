.grid {
	margin: 4rem;

	display: grid;
	grid-template-areas:
		"hero"
		"content"
		"footer";

	gap: 4rem;
	width: 100%;
	max-width: $xxlMax;
}