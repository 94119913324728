.blink {
	animation: blink 1s infinite;
}

@keyframes blink {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 0;
	}

	51% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

.rotate {
	animation: rotate 210s infinite linear;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}