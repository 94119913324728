body {
	font-family: -apple-system, BlinkMacSystemFont, system-ui, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 600;
	margin: 0;
	padding: 0;
	line-height: 1;
}

p {
	font-size: 1.25rem;
}