:root {
  --primary: #38dab0;
  --white: #fff;
  --black: #0c0c0c;
  --background-color: #e8e8e8;
  --background-color-gradient: #fff;
  --foreground-color: #1c1c1c;
  --color: #494949;
  --color2: #9b9b9b;
  --shadow-color: #0c0c0c;
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary: #38dab0;
    --white: #fff;
    --black: #0c0c0c;
    --background-color: #1c1c1c;
    --background-color-gradient: #0c0c0c;
    --foreground-color: #e8e8e8;
    --color: #e8e8e8;
    --color2: #9b9b9b;
    --shadow-color: #38dab055;
  }
}

.blink {
  animation: 1s infinite blink;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  51% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.rotate {
  animation: 210s linear infinite rotate;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.apps .h2.headline {
  margin-bottom: 5rem;
  font-size: 2rem;
}

.apps .app-list {
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  gap: 4rem 2rem;
  display: grid;
}

.apps .app-list .app a {
  color: #fff;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
  display: flex;
}

.apps .app-list .app a .icon {
  width: 8rem;
  height: 8rem;
  justify-content: center;
  place-items: center;
  padding: 2rem;
  display: flex;
  position: relative;
  -webkit-transform: translateZ(0);
}

.apps .app-list .app a .icon:before {
  content: "";
  z-index: -1;
  filter: drop-shadow(0 0 .75rem #ffffff4d);
  background-image: url("kf_background.de230e52.svg");
  background-size: 100%;
  position: absolute;
  inset: 0;
}

.apps .app-list .app a .icon img {
  max-width: 100%;
  max-height: 100%;
}

.apps .app-list .app a .app-name {
  color: var(--color);
  font-size: 1.5rem;
}

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  color: var(--color);
  background: linear-gradient(61deg, var(--background-color) 0%, var(--background-color-gradient) 100%);
  justify-content: center;
  align-items: center;
  font-size: 16px;
  display: flex;
}

a {
  color: var(--color2);
}

a:hover, a:focus {
  color: var(--primary);
  text-decoration: underline;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.downloadButton {
  width: 250px;
  display: block;
  overflow: hidden;
}

.downloadButton img {
  width: 100%;
}

.card {
  grid-template-columns: max-content 1fr;
  grid-template-areas: "icon headline"
                       "icon paragraph";
  gap: 1rem 2rem;
  display: grid;
  position: relative;
}

.card .icon {
  width: 4rem;
  grid-area: icon;
}

.card .icon img {
  width: 100%;
}

.card .card-headline {
  grid-area: headline;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
}

.card p {
  grid-area: paragraph;
  margin-top: 0;
}

.content {
  flex-direction: column;
  grid-area: content;
  gap: clamp(5rem, 10vw, 6.875rem);
  margin: 10rem 0;
  display: flex;
}

.content section .h2 {
  text-align: center;
  font-size: 3rem;
}

footer {
  flex-direction: row;
  grid-area: footer;
  justify-content: space-between;
  align-items: center;
  font-size: .8rem;
  display: flex;
}

footer ul {
  flex-direction: column;
  gap: .25rem 1rem;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

@media (min-width: 768px) {
  footer ul {
    flex-direction: row;
  }
}

footer ul li a {
  color: #494949;
}

footer .logo {
  width: 3rem;
}

@media (prefers-color-scheme: dark) {
  footer .logo {
    filter: invert(100);
  }
}

.grid {
  width: 100%;
  max-width: 1400px;
  grid-template-areas: "hero"
                       "content"
                       "footer";
  gap: 4rem;
  margin: 4rem;
  display: grid;
}

.hero {
  height: calc(100vh - 8rem);
  flex-direction: column;
  grid-area: hero;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  display: flex;
}

.hero .icon {
  --padding: 2.5rem;
  width: clamp(1rem, 75%, 30rem);
  aspect-ratio: 1;
  grid-area: icon;
  justify-content: center;
  justify-self: center;
  align-items: center;
  display: flex;
  position: relative;
}

.hero .icon img {
  width: 100%;
  height: 100%;
  position: absolute;
}

@media (prefers-color-scheme: dark) {
  .hero .icon img {
    filter: invert(100);
  }
}

.hero .hero-content {
  text-align: center;
  grid-area: hero-content;
  font-size: 1.25rem;
}

@media (min-width: 1200px) {
  .hero .hero-content {
    text-align: start;
  }
}

.hero .hero-content .headline {
  grid-area: headline;
  margin-bottom: 3.125rem;
  font-family: monospace;
  font-size: clamp(3rem, 10vw, 4.75rem);
  line-height: 1;
}

.hero .hero-content .caption {
  color: var(--color2);
  font-size: .9rem;
}

.hero .hero-content .downloadButton {
  margin: 2rem auto 0;
}

@media (min-width: 1200px) {
  .hero .hero-content .downloadButton {
    margin: 2rem 0 0;
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, system-ui, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  line-height: 1.5;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-weight: 600;
  line-height: 1;
}

p {
  font-size: 1.25rem;
}

/*# sourceMappingURL=index.64f242f7.css.map */
