.hero {
	grid-area: hero;

	height: calc(100vh - 4rem - 4rem);

	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	gap: 2rem;


	.icon {
		--padding: 2.5rem;
		position: relative;
		grid-area: icon;
		display: flex;
		align-items: center;
		justify-content: center;

		display: flex;
		justify-self: center;

		width: clamp(1rem, 75%, 30rem);
		aspect-ratio: 1;

		img {
			position: absolute;
			width: 100%;
			height: 100%;
		}

		@media (prefers-color-scheme: dark) {
			img {
				filter: invert(100);
			}
		}
	}

	.hero-content {
		grid-area: hero-content;
		font-size: 1.25rem;
		text-align: center;

		@media (min-width: $xlMax) {
			text-align: start;
		}

		.headline {
			grid-area: headline;
			font-size: clamp(3rem, 10vw, 4.75rem);
			margin-bottom: 3.125rem;
			line-height: 1;
			font-family: monospace;
		}

		.caption {
			font-size: 0.9rem;
			color: var(--color2);
		}

		.downloadButton {
			margin: 2rem auto 0;

			@media (min-width: $xlMax) {
				margin: 2rem 0 0;
			}
		}
	}
}