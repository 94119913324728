.card {
	position: relative;
	display: grid;
	gap: 1rem 2rem;
	grid-template-areas:
		"icon headline"
		"icon paragraph";
	grid-template-columns: max-content 1fr;

	.icon {
		grid-area: icon;
		width: 4rem;

		img {
			width: 100%;
		}
	}

	.card-headline {
		grid-area: headline;
		line-height: 1;
		font-size: 1.5rem;
		font-weight: 700;
		margin: 0;
	}

	p {
		grid-area: paragraph;
		margin-top: 0;
	}
}