.apps {
	.h2.headline {
		font-size: 2rem;
		margin-bottom: 5rem;
	}

	.app-list {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
		gap: 4rem 2rem;

		.app {
			a {
				display: flex;
				flex-direction: column;
				gap: 1rem;
				align-items: center;
				color: $white;
				text-decoration: none;

				.icon {
					position: relative;
					width: 8rem;
					height: 8rem;
					padding: 2rem;

					display: flex;
					place-items: center;
					justify-content: center;
					-webkit-transform: translateZ(0);

					&:before {
						content: '';
						position: absolute;
						inset: 0;
						background-image: url(../img/kf_background.svg);
						background-size: 100%;
						z-index: -1;
						filter: drop-shadow(0 0 0.75rem transparentize($white, 0.7));
					}

					img {
						max-width: 100%;
						max-height: 100%;
					}
				}

				.app-name {
					font-size: 1.5rem;
					color: var(--color);
				}
			}
		}
	}
}