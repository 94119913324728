.content {
	grid-area: content;

	display: flex;
	flex-direction: column;
	gap: clamp(5rem, 10vw, 6.875rem);
	margin: 10rem 0;

	section {
		.h2 {
			font-size: 3rem;
			text-align: center;
		}
	}
}